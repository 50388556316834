<template>
  <form class="depositPage" :class="{ 'form-group--error': $v.$error }" @submit.prevent="onSubmit">
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title">
            <h4><i class="fas fa-donate"></i> แจ้งเติมเครดิต v2</h4>
          </div>
        </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-1 mb-5 xtarget col-lotto" v-if="getPermisstionDeposit == false">
          <div class="row w-100 p-0 m-0 d-flex justify-content-center">
            <div id="destep1" class="row col-12 col-sm-12 col-md-6 py-2 px-0 my-1 border rounded">
              <div class="col-12 px-1">
                <h1>ปิดปรับปรุงชั่วคราว</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-1 mb-5 xtarget col-lotto" v-if="getPermisstionDeposit == true">
          <div class="row w-100 p-0 m-0 d-flex justify-content-center">
            <div id="destep1" class="row col-12 col-sm-12 col-md-6 py-2 px-0 my-1 border rounded" >
              <div class="col-12 px-1">
                <h6 class="font-weight-normal">
                  <span class="badge badge-pill badge-success font-weight-normal">STEP 1</span> ใส่จำนวนเงินที่ต้องการ
                </h6>
              </div>
              <div v-if="!confirmDeposit" :style="[confirmDeposit ? { display: 'none' } : '']">
                <div class="col-12">
                  <label><i class="fas fa-money-bill"></i> จำนวนเงิน</label>
                  <input
                    autocomplete="off"
                    type="tel"
                    class="form-control moneyinput"
                    id="CurrencyInput"
                    name="money"
                    maxlength="10"
                    v-model.trim="$v.amount.$model"
                  />
                </div>
                <div class="col-12 pt-1" v-if="promotion_enable">
                  <label><i class="fas fa-gifts"></i> โปรโมชั่น</label>
                  <div class="col-12 text-center pb-2" v-if="promotoion_id > 0">
                    <img @click="$bvModal.show('modal-promotions')" :src="this.current_promotion.picture" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="40%"/>
                  </div>
                    <select @change="change_promotion" class="form-control x-form-control" name="show_promotion" v-model="promotoion_id" >
                      <option value="0">-</option>
                      <option :value="item.id" v-for="(item,index) in getAgentPromotionsDeposit"  v-bind:key="index">{{ item.name }}</option>
                    </select>
                </div>
                <div class="col-12 mt-3">
                  <div class="alert alert-warning">คำเตือน! กรุณาใช้บัญชีที่ท่านผูกกับเว็ป ในการโอนเงินเท่านั้น</div>
                  <div class="alert alert-info">
                    <p class="m-0 p-0">เมื่อสมาชิกเติมเงินแล้วจะต้องมียอดแทง <small> 50% </small>จึงจะสามารถถอนเงินได้ </p>
                    <p class="m-0 p-0">เมื่อท่านทำการโอนเงินไปยังบัญชีข้างต้นเรียบร้อยแล้ว <small>(เก็บสลิปการโอนไว้ทุกครั้ง)</small>
                    <br /><b>"คลิกปุ่มด้านล่าง"</b> <u>เพื่อแจ้งดำเนินการต่อ</u>
                    </p>
                  </div>
                  <button type="button" class="btn btn-primary btn-block btn-larg" @click="submitDeposit">
                    ใส่จำนวนเงินแล้ว คลิกที่นี่
                  </button>
                </div>
              </div>
            </div>

            <div class="w-100"></div>
            <div id="destep2" class="row col-12 col-sm-12 col-md-6 py-2 px-0 my-1 border rounded" v-if="confirmDeposit">
              <div class="col-12 px-1">
                <h6 class="font-weight-normal">
                  <span class="badge badge-pill badge-success font-weight-normal">STEP 2</span> แจ้งรายละเอียดการโอนเงิน
                </h6>
              </div>
              <div class="col-12 text-center">
                <h5><span class="text-danger timetransferlimit"></span></h5>
              </div>
              <div class="col-12">
                <div class="row py-2 px-3 justify-content-center" style="color: green">
                  <div class="col-3 col-sm-3 col-md-4 col-lg-3 pt-2"  v-if="confirmDeposit" >
                    <img
                      :src="imglink(adminBank.bank_icon)"
                      width="100%"
                      id="bank2"
                      class="detail-bank rounded"
                      :style="'background: ' + (adminBank ? adminBank.bank_color: '')"
                    >
                    <p class="numacc text-center" id="acc2" style="margin: 0 0 0.25rem 0">
                      ธ. {{ adminBank.bank_name }}
                    </p>
                  </div>
                  <div
                    class="
                      col-9 col-sm-9 col-md-8 col-lg-6
                      p-2
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-start
                    "
                    v-if="confirmDeposit"
                  >
                    <span class="badge badge-pill badge-success font-weight-normal">เลขบัญชี</span>
                    <h6 class="numacc" id="acc2" style="margin: 0 0 0.25rem 0">
                      {{ adminBank.acount_number }}
                    </h6>
                    <span class="badge badge-pill badge-success font-weight-normal">ชื่อบัญชี</span>
                    <span id="name2" style="font-size: 1rem">{{ adminBank.acount_name }}</span>
                    <br/>
                    <button
                    class="btn btn-light btn-sm border border-secondary copyacc mr-3"
                    type="button"
                    style="justify-content: center; align-items: center"
                    v-clipboard:copy="adminBank.acount_number"
                    v-on:click="copyAlert"
                  >
                    <i class="fas fa-copy mr-1"></i>COPY
                  </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label><i class="fas fa-money-bill"></i> จำนวนเงินที่ต้องโอน</label>
                    <input
                      type="tel"
                      class="form-control text-center text-danger moneyinput f4vh"
                      id="CurrencyInput"
                      name="money"
                      maxlength="10"
                      v-model.trim="$v.amount.$model"
                      disabled
                    />
                  </div>
                  <div class="col-12 mt-1">
                    <label><i class="fas fa-calendar-alt"></i> ยืนยันการโอนเงิน </label>

                    <b-form-file
                      v-model="fileImg"
                      :state="Boolean(fileImg)"
                      accept="image/jpeg, image/png"
                      placeholder="แนบหลักฐานการโอนเงินตรงนี้"
                      drop-placeholder="Drop file here..."
                      multiple=""
                      @change="onUploadFiles"
                    ></b-form-file>
                    <input type="submit" @click="verifyDeposit" class="btn btn-primary btn-block btn-larg mt-1" value="Upload" />
                  <!-- <form target="_blank" action="http://localhost:8000/bank/verifyDeposit" method="post" enctype="multipart/form-data">
                      <div class="form-group">
                      </div>
                      <b-form-file
                      name="image"
                      accept="image/jpeg, image/png"
                      placeholder="แนบหลักฐานการโอนเงินตรงนี้"
                      drop-placeholder="Drop file here..."
                      multiple=""
                      ></b-form-file>
                      <input  type="hidden" :value="adminBank.transaction_code" name="transaction_code"/>
                      <br /><br />
                      <input type="submit" class="btn btn-primary btn-block btn-larg" value="Upload" />
                    </form> -->
                  </div>
                  <div class="col-12 mb-2">
                    <small>กรุณาตรวจสอบข้อมูลของท่านให้ถูกต้อง</small>
                  </div>
                  <div class="col-12 mb-2 text-center">
                    <i class="sn-icon sn-icon--daily2"></i>
                    <h2
                      class="countdown_deposit"
                      data-finaldate="1610980200000"
                      v-html="calTime(this.create_time)"
                      v-if="this.create_time !== ''"
                    ></h2>
                  </div>
                </div>
                <!--<button class="btn btn-success btn-block btn-larg" type="submit">ยืนยันการแจ้งโอนเงิน</button>-->
                <a class="btn btn-danger btn-block btn-larg" @click="calcleBankStagement" :class="btnCancelEnable ? '' : 'disabled'">ยกเลิก</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal style="height: 100%;" id="modal-promotions" scrollable hide-footer size="xl" v-if="promotoion_id > 0">
      <img  :src="this.current_promotion.picture"  class="w-100 bg-radius">
                        <h1 class="m-0 p-2 text-white">{{ this.current_promotion.name }}</h1>
                        <p class="m-0 px-2  text-white">{{ this.current_promotion.note }}</p>
      <b-button class="mt-3" variant="primary" size="sm" block @click="$bvModal.hide('modal-promotions')">ปิด</b-button>
    </b-modal>
  </form>
</template>

<script>
// import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters } from 'vuex'
import { checkNumeric, isEmpty, get } from '@/utils/'
import { submitDepositServiceP2p, veriryDepositServiceType2, depositCachViewService, getStatusDepService, CancleDepositTransferService } from '@/services'
import { STATUS_SUCCESS } from '@/constants'
import kbank from '@/assets/banks/kbank.svg'
import ktb from '@/assets/banks/ktb.svg'
import scb from '@/assets/banks/scb.svg'
import bbl from '@/assets/banks/bbl.svg'
import uob from '@/assets/banks/uob.svg'
import bay from '@/assets/banks/bay.svg'
import gsb from '@/assets/banks/gsb.svg'
import baac from '@/assets/banks/baac.svg'
import tisco from '@/assets/banks/tisco.svg'
import kkp from '@/assets/banks/kkp.svg'
import ttb from '@/assets/banks/ttb.svg'

import moment from 'moment-timezone'
export default {
  components: {
    // VueTimepicker
  },
  data () {
    return {
      promotoion_id: 0,
      currentDate: moment(),
      fileImg: null,
      date: this.$moment().format('DD/MM/YYYY'),
      adminBank: '',
      confirmDeposit: false,
      confirmSlip: true,
      note: '',
      amount: '',
      time_trasfer: this.$moment().format('HH:mm'),
      time_ban: [],
      create_time: '',
      masks: {
        input: 'DD/MM/YYYY'
      },
      modelConfig: {
        type: 'string',
        input: 'DD/MM/YYYY',
        mask: 'DD/MM/YYYY'
      },
      btnCancelEnable: false,
      imageBankMapping: {
        '003': bbl,
        '001': kbank,
        '004': ktb,
        '010': scb,
        '016': uob,
        '017': bay,
        '022': gsb,
        '025': baac,
        '029': tisco,
        '023': kkp,
        '007': ttb
      },
      timeInterval: 5000,
      current_promotion: {},
      promotion_enable: process.env.VUE_APP_PROMOTION_ENABLE
    }
  },
  async mounted () {
    await this.setLoading(true)
    await this.reqConfig(process.env.VUE_APP_AG_ID)
    if (Number(this.getDepositMode) === Number(0)) {
      this.$router.replace('/member/deposit')
    }
    // if (Number(this.getDepositMode) === Number(1)) {
    //   this.$router.replace('/member/depositP')
    // }
    if (Number(this.getDepositMode) === Number(2)) {
      this.$router.replace('/member/depositCP')
    }
    // this.adminListBank = await this.inquiryListBank()
    await this.getMyAccountBanks()
    const resp = await depositCachViewService()
    if (resp.status_code === STATUS_SUCCESS) {
      if (isEmpty(resp.data) === false) {
        this.create_time = resp.data.date_create
        this.adminBank = resp.data
        this.amount = resp.data.amount
        this.confirmDeposit = true
        // this.amount = resp.data.amount
        // this.confirmDeposit = true
        // this.adminBank = resp.data.bankInfo
        this.timer = setInterval(() => {
          this.getMyStagementDep()
        }, this.timeInterval)
      }
    }
    for (let i = 0; i < 10; i++) { // 10 minute after Asia/Bangkok
      const timePush = moment().tz('Asia/Bangkok').add(i, 'minute').format('HH:mm')
      this.time_ban.push(timePush)
    }
    await this.setLoading(false)
  },
  computed: {
    ...mapState({
      myBankInfo: state => state.bankModule.myBankInfo
    }),
    ...mapGetters([
      'getDepositMode',
      'getAgentPromotionsDeposit',
      'getPermisstionDeposit'
    ])
  },
  watch: {
    amount: function (value) {
      if (!checkNumeric(value)) {
        this.amount = value.substring(0, value.length - 1)
      } else if (value.length === 1 && (value === '0' || value === '.')) {
        this.amount = ''
      }
    }
  },
  async created () {
    setInterval(() => {
      this.getNow()
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    ...mapActions(['reqConfig', 'inquiryListBank', 'getMyAccountBanks', 'setLoading', 'openInfoPopup', 'openErrorPopup', 'setAdminBank']),
    copyCode: async function (e) {
      this.code = e.target.value
      const copyText = await this.$refs.myInput
      copyText.select()
      document.execCommand('copy')
    },
    getNow () {
      this.currentDate = moment()
    },
    change_promotion () {
      this.current_promotion = this.getAgentPromotionsDeposit.filter((x) => x.id === this.promotoion_id)[0]
      console.log(this.current_promotion)
    },
    calTime (value) {
      if (value === '') {
        return ''
      }
      const now = this.currentDate ? this.currentDate : ''
      const final = moment(value).add(10, 'minutes').diff(now)
      console.log(moment(value), final)
      if (final > 0) {
        let minutes = Math.floor((final % (1000 * 60 * 60)) / (1000 * 60))
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((final % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        if (minutes < 5) {
          this.btnCancelEnable = true
        } else {
          this.btnCancelEnable = true
        }
        return `</small>${minutes}<small>:</small>${seconds}`
      } else {
        this.btnCancelEnable = false
        return '</small>00<small>:</small>00'
      }
    },
    async submitDeposit () {
      await this.setLoading(true)
      try {
        const acountID = 'p2p'
        const resp = await submitDepositServiceP2p(this.amount, acountID, this.promotoion_id)
        if (resp.status_code === STATUS_SUCCESS) {
          this.create_time = resp.data.date_create
          console.log('date_create = ' + resp.data.date_create)
          this.adminBank = resp.data
          this.amount = resp.data.amount
          this.confirmDeposit = true
          this.timer = setInterval(() => {
            this.getMyStagementDep()
          }, this.timeInterval)
        }
      } catch (error) {}
      await this.setLoading(false)
    },
    async onUploadFiles (event) {
      try {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
          this.fileImg = file
        }
        reader.readAsDataURL(file)
        this.makeToast(true)
      } catch (error) {}
      // process your files, read as DataUrl or upload...
      // console.log(event.target.files[0])
    },

    // async onUploadFiles (event) {
    //   try {
    //     const file = event.target.files[0]
    //     this.fileImg = file
    //     this.makeToast(true)
    //   } catch (error) {
    //     console.error('Error handling file upload:', error)
    //   }
    // },
    makeToast (append = false) {
      this.$bvToast.toast('เลือกรูปแล้ว', {
        noCloseButton: true,
        autoHideDelay: 1000,
        variant: 'success',
        appendToast: append,
        solid: false
      })
    },
    async verifyDeposit2 () {
      this.$v.$touch()
      this.setLoading(true)
    },
    async verifyDeposit () {
      await this.setLoading(true)
      if (this.fileImg !== '') {
        try {
          const formData = new FormData()
          formData.append('image', this.fileImg)
          formData.append('transaction_code', this.adminBank.transaction_code)
          formData.append('img_type', 1)
          const resp = await veriryDepositServiceType2(formData)
          if (resp.status_code === STATUS_SUCCESS) {
            this.setAdminBank(this.adminBank)
            this.$router.replace('/member/request/all')
            this.openInfoPopup()
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'ฝากเงินสำเร็จ',
              showConfirmButton: false,
              timer: 2000
            })
            this.confirmSlip = false
          } else {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: resp.status_msg,
              showConfirmButton: false,
              timer: 2000
            })
            this.confirmSlip = true
          }
        } catch (error) {}
        this.fileImg = null
      }
      await this.setLoading(false)
    },
    async onSubmit () {
      // this.$v.$touch()
      // await this.setLoading(true)
      // if (this.$v.$pending || this.$v.$error) {
      //   window.scrollTo(0, 0)
      //   this.openInfoPopup('กรุณากรอกข้อมูลให้ครบถ้วน')
      // } else {
      //   try {
      //     const acountID = this.adminBank.bankID.toString()
      //     const resp = await confirmDepositService(this.amount, acountID, this.time_trasfer, this.note)
      //     if (resp.status_code === STATUS_SUCCESS) {
      //       this.setAdminBank(this.adminBank)
      //       // this.$router.replace('/member/main')
      //       this.openInfoPopup()
      //     }
      //   } catch (error) {
      //   }
      // }
      // await this.setLoading(false)
    },
    async getMyStagementDep () {
      const resp = await getStatusDepService()
      if (resp.status_code === STATUS_SUCCESS) {
        if (resp.data === 'done') {
          clearInterval(this.timer)
          this.timer = null
          this.$router.replace('/member/request/all')
          this.openInfoPopup()
        }
      }
    },
    imglink (short) {
      return get(this.imageBankMapping, [short])
    },
    imgColor (short) {
      return get(this.imageBankMapping, [short])
    },
    copyAlert () {
      this.openInfoPopup()
    },
    async calcleBankStagement () {
      await this.setLoading(true)
      const resp = await CancleDepositTransferService()
      if (resp.status_code === STATUS_SUCCESS) {
        if (resp.data === 'success') {
          clearInterval(this.timer)
          this.timer = null
          this.$router.replace('/member/request/all')
          this.openInfoPopup()
        } else {
          this.openErrorPopup(resp.data)
          // alert(resp.data)
        }
      }
      await this.setLoading(false)
    }
  },
  validations () {
    return {
      amount: {
        required
      },
      date: {
        required
      },
      time_trasfer: {
        required
      }
    }
  }
}
</script>

<style scoped>
.countdown_deposit{
  color: #dc3545;
}
.copytotal,
.copyacc {
  display: flex;
  position: absolute !important;
  bottom: 2px;
  right: 2px;
  padding: 3px 5px;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
.btn-outline-secondary:hover {
  cursor: default;
  color: #6c757d;
  background-color: white;
}
.btn-outline-secondary:active {
  color: #6c757d !important;
  background-color: white !important;
}
.special-append {
  position: absolute;
  right: 0;
}
.f4vh {
  font-size: 3vh;
}
</style>
